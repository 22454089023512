import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import Axios from 'axios';
import { BURNETT_API_ENDPOINT} from './config';
import { ToastContainer } from "react-toastify";
import Button from './components/button';
import Header from './components/header';
import ProfilePlans from './containers/profilePlans';
import "react-toastify/dist/ReactToastify.css";
import './app.scss';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getIdTokenClaims
  } = useAuth0();
  const [syncComplete, setSyncComplete] = useState(false);
  const [userProfile, setUserProfile] = useState(null);

  const syncUserSession = async () => {
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      await Axios.post(`${BURNETT_API_ENDPOINT}/sync-user-session`, {}, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const storedAppUrl = localStorage.getItem('app');

      if (storedAppUrl) {
        try {
          const url = new URL(storedAppUrl);
          localStorage.removeItem('app');
          setTimeout(() => {
            window.location.href = url.href;
          }, 100);
          return;
        } catch (e) {}
      }

      setSyncComplete(true);

    } catch (error) {
      console.error('Error syncing user session:', error);
      setSyncComplete(true);
    }
  };

  useEffect(() => {
    if (!user || !isAuthenticated) return;
    syncUserSession();
  }, [user, isAuthenticated]);


  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const app = urlParams.get('app');

    if (app) {
      try {
        const url = new URL(app);
        if (!url) return;
        localStorage.setItem('app', app);
        urlParams.delete('app');
        const newUrl = `${location.pathname}${urlParams.toString() ? '?' + urlParams.toString() : ''}`;
        navigate(newUrl, { replace: true });
      } catch (e) {}
    }
  }, []);

  const onClickHome = () => {
    const currentUrl = window.location.href;
    if (currentUrl === "http://localhost:3000/profile-plans") {
      window.location.href = "http://localhost:3000";
    } else {
      window.location.href = "https://id.longjump.ai";
    }
  };

  const isLoadingComplete = () => {

  }

  const userProfileData = (data) => {
    setUserProfile(data);
  }

  if (isLoading) {
    return <div></div>;
  }

  if (isAuthenticated) {

    const showProfilePlans = location.pathname.includes('/plans');

    return (
      <>
        <ToastContainer />
        <Header
          logout={logout}
          loginWithRedirect={loginWithRedirect}
          isAuthenticated={isAuthenticated}
          user={user}
          getIdTokenClaims={getIdTokenClaims}
          isLoadingComplete={isLoadingComplete}
          userProfileData={(profileData) => userProfileData(profileData)}
        />

        {showProfilePlans ? (
          // Render the ProfilePlans page when URL contains "/plans"
          userProfile ? <ProfilePlans profileData={userProfile} /> : <></>
        ) : (
          // Otherwise, render the landing page content
          <div className="app-container" style={syncComplete ? {} : { opacity: 0 }}>
            <section>
              <div className="logo" />
              <p>
                How would you like to use
                <br />
                LongJump today?
              </p>
            </section>

            <section>
              <div className="app-cta" onClick={() => window.location.href = 'https://practice.longjump.ai'}>
                <div className="app-cta-logo camera" />
                <div className="app-cta-about">
                  <h2>Practice</h2>
                  <p>Record your video and refine your ideas with LongJump AI</p>
                </div>
              </div>

              <div className='spacer'>
                <div>Or</div>
              </div>

              <div className="app-cta" onClick={() => window.location.href = 'https://compete.longjump.ai'}>
                <div className="app-cta-logo trophy" />
                <div className="app-cta-about">
                  <h2>Compete</h2>
                  <p>Enter your final pitch and compete for prizes and more</p>
                </div>
              </div>
            </section>
          </div>
        )}
  
        <footer>
          <div>© 2024, LongJump AI, All Rights Reserverd</div>
        </footer>
      </>
    );
  } else {
    return (
      <>
        <div className="app-container">
          <section className="login">
            <div className="logo" />
            <p>
              Sign in or create an account
            </p>
            <div className="buttons">
              <Button text="Sign Up" onClick={loginWithRedirect} className="secondary signup-button" />
              <Button text="Log in" onClick={loginWithRedirect} className="primary login-button" />
            </div>
          </section>
        </div>
        <footer>
          <div>© 2024, LongJump AI, All Rights Reserverd</div>
        </footer>
      </>
    );
  }
}

export default App;
